/* CSS */
import "@js/parts/css";

/**
 * Alpine JS
 * Best to put it last so that all other potential JS is available
 * when components start getting initialized.
 */
import Alpine from 'alpinejs';
window.Alpine = Alpine;
Alpine.start();

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR");
  });
}


import Swiper from 'swiper';
// core version + navigation, pagination modules:
import SwiperCore, { Navigation, Pagination, Autoplay, EffectFade } from 'swiper/core';

// configure Swiper to use modules
SwiperCore.use([Navigation, Pagination, Autoplay, EffectFade]);

;(function () {

  'use strict';

  let sliderElems = document.querySelectorAll('.swiper-container');
  let sliders = [];

  // const sizeSlider = function(elem) {
  //     let height = elem.parentNode.parentNode.clientHeight;
  //     let compStyles = window.getComputedStyle(elem.parentNode.parentNode.querySelector('div'));
  //     height = height - parseInt(compStyles.getPropertyValue('margin-top'));
  //     elem.style.height = height+"px";
  //     let sliderImages = elem.querySelectorAll('img');
  //     Array.prototype.forEach.call(sliderImages, function(img) {
  //         img.style.height = height+"px";
  //     });
  // };

  Array.prototype.forEach.call(sliderElems, function(elem, index) {
    // sizeSlider(elem);
    let swiperConfig = JSON.parse(elem.dataset.swiper);
    let swiperInit = Object.assign({}, swiperConfig, {
      spaceBetween: 32,
    });
    // let swiperInit = Object.assign({}, swiperConfig, {
    //     navigation: {
    //         nextEl: '.swpr-next',
    //         prevEl: '.swpr-prev',
    //     },
    //     spaceBetween: 32,
    // });
    sliders[index] = new Swiper(elem, swiperInit);
  });

  // const swiper = new Swiper('.swiper-container', {
  //     // Navigation arrows
  //     navigation: {
  //         nextEl: '.swpr-next',
  //         prevEl: '.swpr-prev',
  //     },
  // });

  // const closestElem = function(num, arr) {
  //     let mid;
  //     let lo = 0;
  //     let hi = arr.length - 1;
  //     while (hi - lo > 1) {
  //         mid = Math.floor ((lo + hi) / 2);
  //         if (arr[mid] < num) {
  //             lo = mid;
  //         } else {
  //             hi = mid;
  //         }
  //     }
  //     if (num - arr[lo] <= arr[hi] - num) {
  //         return arr[lo];
  //     }
  //     return arr[hi];
  // };
  //
  // const scrollSections = document.querySelectorAll('.section');
  //
  // let sectionOffsets;
  //
  // const calculateSectionOffsets = function() {
  //     sectionOffsets = [];
  //
  //     Array.prototype.forEach.call(scrollSections, function(item) {
  //         sectionOffsets.push(item.getBoundingClientRect().top + window.scrollY);
  //     });
  // };
  //
  // const bodyElem = document.querySelector('body');
  //
  // let scrollTimeout, ignoreScrollTimeout;
  //
  // let reactToScroll = true;
  //
  // const scrollSnap = function(closestOffset) {
  //     let index = sectionOffsets.indexOf(closestOffset);
  //
  //     reactToScroll = false;
  //
  //     scrollSections[index].scrollIntoView({behavior: 'smooth'});
  //
  //     ignoreScrollTimeout = setTimeout(function() {
  //         reactToScroll = true;
  //     }, 1000);
  // };

  // window.addEventListener('resize', function(ev) {
  // calculateSectionOffsets();
  // let top = Math.abs(bodyElem.getBoundingClientRect().top);
  // let closestSection = closestElem(top, sectionOffsets);
  // scrollSnap(closestSection);

  // Array.prototype.forEach.call(sliderElems, function(elem) {
  // sizeSlider(elem);
  // });
  // });

  // window.addEventListener('scroll', function(ev) {
  //     let top = Math.abs(bodyElem.getBoundingClientRect().top);
  //     let closestSection = closestElem(top, sectionOffsets);
  //
  //     clearTimeout(scrollTimeout);
  //
  //     scrollTimeout = setTimeout(function() {
  //         if (reactToScroll) {
  //             clearTimeout(ignoreScrollTimeout);
  //             scrollSnap(closestSection);
  //         }
  //     }, 150);
  // });

  // let event = new Event(
  //     'resize',
  //     {
  //         bubbles: true,
  //         cancelable: true
  //     }
  // );
  //
  // window.dispatchEvent(event);
})();

// Menu
// (function() {
//     // get button and menu nodes
//     let button = document.querySelector('#nav-button');
//     let buttonShow = button.querySelector('#nav-show');
//     let buttonHide = button.querySelector('#nav-hide');
//     let menu = button.nextElementSibling;
//     // set initial (closed menu) states
//     button.setAttribute('aria-expanded', 'false');
//     button.hidden = false;
//     buttonShow.classList.remove('hidden');
//     buttonHide.classList.add('hidden');
//     menu.hidden = true;
//     button.addEventListener('click', function() {
//         // toggle menu visibility
//         let expanded = this.getAttribute('aria-expanded') === 'true';
//         this.setAttribute('aria-expanded',String(!expanded));
//         menu.hidden = expanded;
//         if (!expanded) {
//             buttonShow.classList.add('hidden');
//             buttonHide.classList.remove('hidden');
//         } else {
//             buttonShow.classList.remove('hidden');
//             buttonHide.classList.add('hidden');
//         }
//     });
// })();

// Search
// (function() {
//     // get search button and form nodes
//     let searchButton = document.querySelector('[data-search-button]');
//     let searchForm = document.querySelector('[data-search-form]');
//     let searchField = document.querySelector('[data-search-field]');
//     // set initial (closed search) states
//     searchButton.setAttribute('aria-expanded', 'false');
//     searchForm.hidden = false;
//     searchButton.addEventListener('click', function() {
//         // toggle search form and field focus
//         let expanded = this.getAttribute('aria-expanded') === 'true';
//         expanded = !expanded;
//         this.setAttribute('aria-expanded',String(expanded));
//         searchForm.hidden = expanded;
//         if (!expanded) {
//             searchForm.classList.add('hidden');
//         } else {
//             searchForm.classList.remove('hidden');
//             searchField.focus();
//         }
//     });
// })();
